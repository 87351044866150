import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';
import PostSlideCard from '../../../components/post-slide-card/post-slide-card';
import PostSliderWrapper, {PostButtonsWrapper, PrevButton, NextButton}  from './style';

SwiperCore.use([Navigation, Pagination]);

import "swiper/css";
import "swiper/css/navigation";

const SimpleSwiper = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      allContentfulSlideshow {
        edges {
          node {
            description
            image {
              gatsbyImageData(width: 1200, height: 630)
              createdAt(formatString: "MMM DD, YYYY")
            }
            title
            slug
           
          }
        }
      }     
    }
  `);

  // externalLink
  const posts = data.allContentfulSlideshow.edges;

  return (
    <PostSliderWrapper>
      <PostButtonsWrapper>
      <PrevButton className="ps-button-prev" aria-label="prev"/>
        <NextButton className="ps-button-next" aria-label="next" />
      </PostButtonsWrapper>
      <Swiper
        speed={600}
        spaceBetween={30}
        navigation={{
          prevEl: '.ps-button-prev',
          nextEl: '.ps-button-next',
        }}
        modules={[ Navigation]}
        pagination={{
          clickable: true,
        }}
      >
        {posts.map(({ node }: any) => (
          <SwiperSlide key={node.id}>
            <PostSlideCard
              title={ node.title || node.slug}
              image={
                node.image == null
                  ? null
                  : node.image.gatsbyImageData
              }
              slug={node.slug}
              url={node.externalLink}
              description={node.description}
            />
          </SwiperSlide>
        ))}
  
      </Swiper>
    </PostSliderWrapper>
  );
};

export default SimpleSwiper;
