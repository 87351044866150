 import {graphql, useStaticQuery} from 'gatsby'
const useInstagram = () => {
    const data = useStaticQuery(graphql`
    query {
        allInstaNode(limit:10) {
         nodes {
           id
           username
           permalink
           localFile {
            childImageSharp {
              gatsbyImageData(width: 275, webpOptions: {}, height: 275)
            }
          }
         }
       }
       }
    `)
    return data.allInstaNode.nodes.map((node:any) => ({
        image: node.localFile.childImageSharp,
        id: node.id,
        caption: node.caption,
        username: node.username,
        permalink: node.permalink
    }))   

    return null;
  
}
export default useInstagram 