import React from 'react';
import Layout from '../containers/layout';
import PersonalBlog from '../containers/home';
import useSiteMeta from "../hooks/use-site-metadata";
import SEO from '../components/seo';


const HomePage = () => {
  const siteMeta: any = useSiteMeta();
  return (
    <Layout>
      <SEO 
      title={`Laipni lūdzam Reeni Deco`}
      description={ siteMeta.description || `Reeni Deco ir radošā aģentūra, kas piedāvā pasākumu dekorēšanu, reklāmas un telpu dizaina noformējumu, kā arī meistarklases un dekorāciju nomu.`}
       />
      <PersonalBlog />
    </Layout>
  );
};

export default HomePage;


