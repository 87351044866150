import * as React from 'react';
import { Link } from 'gatsby';
import _ from 'lodash';


import {
  Button,
  PostSlideCardWrapper,
  PostPreview,
  SliderImage,
  ImageWrapper,
  PostDetails,
  PostTitle,
  ReadMore,
  Excerpt,
} from './post-slide-card.style';
interface PostSlideCardProps {
  image?: any;
  title: string;
  description?: string;
  url: string;
  slug: string;
  className?: string;
}

const PostSlideCard: React.FunctionComponent<PostSlideCardProps> = ({
  image,
  title,
  description,
  url,
  slug,
  className,
  ...props
}) => {
  const addClass = ['featured_card'];

  if (className) {
    addClass.push(className);
  }


  return (
    <PostSlideCardWrapper className={addClass.join(' ')} {...props}>
      <PostPreview className=''>
        <ImageWrapper>
        <SliderImage src={image} alt="post preview" backgroundColor="transparent" /> 
       </ImageWrapper>
      </PostPreview>
      <PostDetails className='post_details'>
        <PostTitle className='post_title'>
          <Link to={slug}>{title}</Link>
        </PostTitle>

        {description && (
          <Excerpt
            dangerouslySetInnerHTML={{
              __html: description,
            }}
            className='excerpt'
          />
        )}

        <ReadMore className='read_more'>
          <Link to={slug}>
            <Button title='Lasīt vairāk' type='submit' />
          </Link>
        </ReadMore>
      </PostDetails>
    </PostSlideCardWrapper>
  );
};

export default PostSlideCard;
