import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const PostSliderWrapper = styled.div`
  position: relative;
  && {
    .swiper-pagination {
      bottom: 0;

      .swiper-pagination-bullet{
        background: ${themeGet('colors.primary', '#CFB8A8')};

      }

      .swiper-pagination-bullet-active {
     
        width: 10px;
        height: 10px;
        vertical-align: middle;
      }
    }
  }
`;

export const PostButtonsWrapper = styled.div`
 position: absolute;
 z-index: 20;
 width: 100%;
 padding-bottom: 40%;
 @media (max-width: 767px) {
  padding-bottom: 56.25%;
}
`
export const PrevButton = styled.div`
position: absolute;
cursor: pointer;

top: calc(50% - 16px);
left: 20px;
&::after {
  color: ${themeGet('white', '#fff')};
  content: 'prev';
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  text-transform: none!important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;

}

&.swiper-button-disabled {

  opacity: .35;
  cursor: auto;
  pointer-events: none;
  
}



`;

export const NextButton = styled.div`
position: absolute;
cursor: pointer;
top: calc(50% - 16px);
right: 20px;
&::after {
  color: ${themeGet('white', '#fff')};
  content: 'next';
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  text-transform: none!important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;

}
&.swiper-button-disabled {

  opacity: .35;
  cursor: auto;
  pointer-events: none;
  
}
`;

export default PostSliderWrapper;
