import * as React from 'react';
import GatsbyImage from '../../components/gatsby-image';
import {
  InstagramGridWrapper,
  Overlay,
} from './instagram-grid.style';

type InstagramGridProps = {
  image: any;
  alt?: string;
  url: string;
};

const InstagramGrid: React.FunctionComponent<InstagramGridProps> = ({
  image,
  alt,
  url,
}) => {
  return (
    <InstagramGridWrapper>
      <a href={url}>
        <Overlay />
        <GatsbyImage src={image} alt="" /> 
      </a>
    </InstagramGridWrapper>
  );
};

export default InstagramGrid;
