import * as React from 'react';
import PersonalBlogWrapper from './style';
import SimpleSwiper from './post-slider'
import ReviewSwiper from '../../components/testimonial-slider';
import InstagramShowcase from "../instagram-showcase";
//import Posts from './posts';

type PersonalBlogProps = {};

const breakpoints:any = {
  768: {
    slidesPerView: 2,
    spaceBetween: 20,
    navigation:true
  },
  1024: {
    slidesPerView: 3,
    spaceBetween: 30,
    navigation:false
  },
}

const PersonalBlog: React.FunctionComponent<PersonalBlogProps> = ({
  ...props
}) => {
  return (
    <PersonalBlogWrapper {...props}>
      <SimpleSwiper />
    <InstagramShowcase />  
      <ReviewSwiper breakpoints={breakpoints} navigation={false}  />
    </PersonalBlogWrapper>
  );
};

export default PersonalBlog;
